import Head from 'next/head';
import { decodeHTMLEntities } from '@/utils/common';
// import { useRouter } from "next/router";
import React from 'react';

interface Props {
  children?: React.ReactNode;
  seo?: any;
}

// let resourceUrl = process.env.NEXT_PUBLIC_REACT_APP_RESOURCE_BASE_URL;

const HocHead: React.FC<Props> = ({ children, seo = {} }) => {
  // const router = useRouter();
  // const canonicalURL = router.asPath;

  // const formatJson = (json) => {
  //   return {
  //     __html: `${JSON.stringify(json, null, 2)}`,
  //   };
  // };

  const formatJson = (json: any) => {
    return { __html: `${JSON.stringify(json, null, 2)}` };
  };

  return (
    <Head>
      <meta charSet="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      {!!seo?.metaTitle ? (
        <title>{decodeHTMLEntities(seo?.metaTitle)}</title>
      ) : (
        <title>Trootech</title>
      )}

      <link
        rel="apple-touch-icon"
        sizes="60x60"
        href="/images/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        href="/images/favicon-32x32.png"
        sizes="32x32"
      />
      <link
        rel="icon"
        type="image/png"
        href="/images/favicon-16x16.png"
        sizes="16x16"
      />

      <meta property="og:type" content="website" />
      <meta property="twitter:card" content="summary_large_image" />

      {seo?.canonicalURL && <link rel="canonical" href={seo.canonicalURL} />}

      {seo?.structuredData && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={formatJson(seo.structuredData)}
        />
      )}

      {children ? children : <></>}
    </Head>
  );
};

export default HocHead;
